import React, { useEffect } from 'react'
import { Navigate, useParams } from "react-router-dom";
import { AuthGet } from "../../_common/httpService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailverifyImg from "../../assets/slices/emailverify_img.png";
import Mainlogo from "../../assets/slices/main_logo.svg";

const EmailVerify = () => {
    const param = useParams()
    sessionStorage.setItem("subscriptionId", param.id)
    useEffect(() => {
        verifySubscription()
        getBusinessName()
    }, [])

    const verifySubscription = () => {
        let subscriptionId = sessionStorage.getItem("subscriptionId")
        AuthGet(`tenantmaster/verify/${subscriptionId}`)
            .then((res) => {
                if (res.statusCode === 200) {
                    toast.success('Subscription Verified Successfully', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch((err) => {
            })
    }

    const getBusinessName = () => {
        let subscriptionId = sessionStorage.getItem("subscriptionId")
        AuthGet(`tenantmaster/businessName/${subscriptionId}`)
            .then((res) => {
                let businessName = (res["tenantInfo"][0]["businessName"])
                let business_url = businessName
                    .toString()
                    .toLowerCase()
                    .replace(/\s/g, "");
                window.location.href = `https://${business_url}.theecentral.com/admin/login`
            })
            .catch((err) => {
            })
    }
    return (
      <>
        <ToastContainer />
        <div className="fullScreen_bg">
          <div className="container_wrapper">
            <div className="whiteFrame p30">
              <div className="textAlign_center mb_50">
                <img src={EmailverifyImg} />
              </div>
              <h1 className="headingTitle24 textAlign_center mb_12">
                Your email address has been verified.
              </h1>

              <p className="textAlign_center mb_12 fontWeight_400 ashtoAsh_Color">
                the address we currently have for your account is
              </p>
              <a className="fontWeight_600 textAlign_center displayBlock blackText">
              glenzyfi.com
              </a>
              <hr />

              <p className="textAlign_center subparagraph_text mb_24">
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs. The passage is
                attributed to an unknown typesetter in the 15th century who is
                thought to have scrambled parts of Cicero's De Finibus Bonorum
                et Malorum for use in a type specimen book.
              </p>

              <div className="textAlign_center">
                <img src={Mainlogo} />
              </div>

              {/*  <p>
                Your Subscription is Verified! On Sucessfull Verification, you will
                be Redirected to Application Portal.
            </p> */}
            </div>
          </div>
        </div>
      </>
    );
}

export default EmailVerify