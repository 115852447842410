import React, { useState, useEffect } from "react";
import { env_variables } from "../../../../_common/environmentVariables";
import { AuthPost, AuthGet } from "../../../../_common/httpService";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import HealthCareLogo from "../../../../assets/slices/Health_Care.svg";
import Paymenticon from "../../../../assets/slices/Paymenticon.svg";
import de_icon from "../../../../assets/slices/Business_Decision.svg";
import lmsicon from "../../../../assets/slices/lmsicon.svg";





const Api = (props) => {
  let spliturl = props.apiurl.split("//");
  let secondsplit = spliturl[1].toString().split(".");
  let url = `https://${secondsplit[0]}.${process.env.REACT_APP_ADMIN_URL_DOMAIN}/admin-api/`;


  // let url1 = "http://localhost:7088/api/";
  let subscription_id = sessionStorage.getItem("subscriptionId");
  const [swagger, setswagger] = useState("");
  const [showAdminsagger, setshowAdminswagger] = useState(false);
  const [showDesagger, setshowdeswagger] = useState(false);
  const [showLmsSwagger, setshowLmsswagger] = useState(false);
  const [showPayementgatewaysSwagger, setshowPaymentGatewaysSwagger] = useState(false);




  const getSwaggerUrl = () => {
    AuthGet(`control-panel/apilist/${subscription_id}`)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data[0].apiurl);
          setswagger(res.data[0].url);
        }
      })
      .catch((err) => {});
  };

  const handleClose = () => {
    setshowAdminswagger(false);
    setshowdeswagger(false);
    setshowLmsswagger(false);
    setshowPaymentGatewaysSwagger(false);
    
  };
  useEffect(() => {
    getSwaggerUrl();
  }, []);
  return (
    <>
      <div className="whiteboxblock minmax_height220">
        <h3 className="  mb_40  sectiontitle  ">Swagger API</h3>

        <div className="row p0  Alignitem_center">
          <div className="col-md-3" onClick={() => setshowAdminswagger(true)}>
          <div className="apiFrame ">
                  <div>
                      {/* <div className="bankFrame"> */}
              <img className="w50" src={HealthCareLogo} />
            {/* </div> */}
                  </div>
                      
                     <div>
                     <h3 className="    sectiontitle  ">
                        {" "}
                        Health Admin API
                      </h3>

                      <div className="clearBoth"></div>
                     </div>
                    </div>
          </div>
          <div className="col-md-3" onClick={() => setshowdeswagger(true)}>
            
          <div className="apiFrame ">
                  <div>
                      {/* <div className="bankFrame"> */}
              <img className="w50" src={de_icon} />
            {/* </div> */}
                  </div>
                      
                     <div>
                     <h3 className="    sectiontitle  ">
                        {" "}
                        Decision Engine API
                      </h3>

                      <div className="clearBoth"></div>
                     </div>
                    </div>
          </div>

          <div className="col-md-3" onClick={() => setshowLmsswagger(true)}>
            
          <div className="apiFrame ">
                  <div>
                      {/* <div className="bankFrame"> */}
              <img className="w50" src={lmsicon} />
            {/* </div> */}
                  </div>
                      
                     <div>
                     <h3 className="    sectiontitle  ">
                        {" "}
                        LMS API
                      </h3>

                      <div className="clearBoth"></div>
                     </div>
                    </div>
          </div>

          <div className="col-md-3" onClick={() => setshowPaymentGatewaysSwagger(true)}>
            
          <div className="apiFrame ">
                  <div>
                      {/* <div className="bankFrame"> */}
              <img className="w50" src={Paymenticon} />
            {/* </div> */}
                  </div>
                      
                     <div>
                     <h3 className="    sectiontitle  ">
                        {" "}
                        Payement Gateways API
                      </h3>

                      <div className="clearBoth"></div>
                     </div>
                    </div>
          </div>
          
        </div>
      </div>


      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showAdminsagger}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Admin API
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row p0">
              <div className="col-md-12">
                <div className="whiteboxblock100per p0">
                  {/* <h3 className="  mb_40  sectiontitle  ">API</h3> */}
                  <iframe
                    style={{
                      position: "absolute",
                      height: "calc(100% - 100px)",
                      border: "none",
                    }}
                    src={url}
                    frameBorder="2"
                    width={"95%"}
                  ></iframe>
                  <div className="clearBoth"></div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDesagger}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Decision Engine API
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row p0">
              <div className="col-md-12">
                <div className="whiteboxblock100per p0">
                  {/* <h3 className="  mb_40  sectiontitle  ">API</h3> */}
                  <iframe
                    style={{
                      position: "absolute",
                      height: "calc(100% - 100px)",
                      border: "none",
                    }}
                    src={process.env.REACT_APP_DE}
                    frameBorder="2"
                    width={"95%"}
                  ></iframe>
                  <div className="clearBoth"></div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showLmsSwagger}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            LMS API
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row p0">
              <div className="col-md-12">
                <div className="whiteboxblock100per p0">
                  {/* <h3 className="  mb_40  sectiontitle  ">API</h3> */}
                  <iframe
                    style={{
                      position: "absolute",
                      height: "calc(100% - 100px)",
                      border: "none",
                    }}
                    src={process.env.REACT_APP_LMSURL}
                    frameBorder="2"
                    width={"95%"}
                  ></iframe>
                  <div className="clearBoth"></div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showPayementgatewaysSwagger}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
         Payment Gateway API
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row p0">
              <div className="col-md-12">
                <div className="whiteboxblock100per p0">
                  {/* <h3 className="  mb_40  sectiontitle  ">API</h3> */}
                  <iframe
                    style={{
                      position: "absolute",
                      height: "calc(100% - 100px)",
                      border: "none",
                    }}
                    src={process.env.REACT_APP_PAYMENTGATEWAY}
                    frameBorder="2"
                    width={"95%"}
                  ></iframe>
                  <div className="clearBoth"></div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>

      
    </>
    //Decsionm

    

    
  );
};

export default Api;
