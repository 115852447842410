import React, { useState, useEffect } from "react";
import { AuthFile, AuthPut } from "../../../../_common/httpService";
import toast from "react-hot-toast";
import Toast from "../../../../Components/Toast";

const Theme = (props) => {
  const [tenantTheme, setTenantTheme] = useState(props?.tenantTheme?.theme);
  const [tenantTheme1, setTenantTheme1] = useState(props?.tenantTheme1?.theme);
  console.log("tenantTheme1::: ", tenantTheme1);

  const [to_beUpdatedTheme, setToBeUpdatedTheme] = useState({});
  console.log('to_beUpdatedTheme::: ', to_beUpdatedTheme);
  const [to_beUpdatedTheme1, setToBeUpdatedTheme1] = useState({});

  let tenantId = sessionStorage.getItem("tenantId");
  let subscriptionId = sessionStorage.getItem("subscriptionId");
  // const themeNames = {
  //   style1: "RedTheme",
  //   style2: "YellowTheme",
  //   style3: "GreenTheme"
  // };

  const handleSelect = (e, theme) => {
    console.log('e::: ', e);
    console.log('theme::: ', theme);
    e.preventDefault();
    setToBeUpdatedTheme({
      selectedThemeId: theme.id,
      selectedTheme: theme.styleName,
      // selectedThemename: themeNames['style1'] 
    });
    setTenantTheme(theme.styleName);
  };


  const handleSelect1 = (e, theme1) => {
    e.preventDefault();
    setToBeUpdatedTheme1({
      selectedThemeId: theme1.id,
      selectedTheme: theme1.styleName,
     
    });
    setTenantTheme1(theme1.styleName);
  };

  const onSubmit = async () => {
    const params = {
      tenantId: tenantId,
      subscriptionId: subscriptionId,
      theme: to_beUpdatedTheme.selectedTheme,
      themeId: to_beUpdatedTheme.selectedThemeId,
      // themeName: themeNames[to_beUpdatedTheme.selectedTheme] // Fetch the readable name
    };
    if (to_beUpdatedTheme) {
      let resp = await AuthPut("tenant-theme/update", params);
      if (resp.statusCode === 200) {
        toast.success(resp.message);
      }
    } else {
      AuthFile(`tenant-theme/${params.themeId}`, params, "patch").then(
        (res) => { }
      );
    }
  };

  useEffect(() => {
    let id = sessionStorage.getItem("tenantId");
  }, []);

  return (
    <>
      <div className="whiteboxblock">
          <Toast />
        <h3 className="  mb_40  sectiontitle  ">
          Select a theme best suits your business
        </h3>


        <div className="row p0 mb_30">
          {props?.data?.map((theme, index) => (
            <div key={index} className="col-md-6">
              <label
                htmlFor="theme"
                onClick={(e) => handleSelect(e, theme)}
                className="SelectTheme"
              >
                <input type="radio" id="theme" style={{ display: "none" }} />
                <div className="themeBox">
                  <img src={theme?.logo} alt="theme" />
                </div>
                {tenantTheme === theme?.styleName ? (
                  <div className="selectBtn selected">Selected</div>
                ) : null}
              </label>
            </div>
          ))}
        </div>
        <div className="textAlign_center">
          <button onClick={onSubmit} className="Mainbutton">
            Update
          </button>
        </div>
      </div>








    </>
  );
};

export default Theme;

