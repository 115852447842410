import React, { useState, useEffect, useRef } from "react";
import { Get, Post, AuthGet } from "../../_common/httpService";
import smb from "../../assets/slices/smb_business.png";
import solar from "../../assets/slices/home_improvement.png";
import nosubs from "../../assets/images/dummy-loan-img.png";
// import healthicon from "../../assets/images/health_loans.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../Components/Pagination";
import Header from "../../Components/Header";
import addSubscriptions from "../../assets/slices/add_Subscriptions.svg";
import gridView from "../../assets/slices/grid_view.svg";
import listView from "../../assets/slices/list_view.svg";
import searchIcon from "../../assets/slices/search_icon.svg";
import { height } from "@mui/system";
import { useStateValue } from "../../Context/StateProvider";

const TenantDashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [query, setQuery] = useState("");
  const [allTheme, setAllTheme] = useState([]);
  const [toggleViewMode, setToggleViewMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const searchRef = useRef(null);
  let tenantId = sessionStorage.getItem("tenantId");
  let tenantToken = sessionStorage.getItem("tenantToken");
  const [{}, dispatch] = useStateValue();

  const getAllSubscriptions = async () => {
    try {
     const resp = await AuthGet(`subscription/getsubscriptionList/${tenantId}`)
     if(resp?.statusCode === 200) {
         setUsers(resp?.data);
         setLoading(false);
      }
    } catch (error) {
      console.log('error::: ', error);
    }
  }

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  // Get data for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentSubscriptions = users?.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  //Search Query Functionality

  const searchFilter = () => {
    let query = searchRef.current.value;
    console.log("poi", query);
    if (data.length > 0 && query) {
      const keys = [
        "business_name",
        "created_date",
        "email",
        "product_name",
        "publish",
        "status",
        "subscription_status",
        "vertical_name",
      ];

      let val = currentSubscriptions.filter((products) =>
        keys.some((key) =>
          products[key]?.toString().toLowerCase().includes(query.toLowerCase())
        )
      );
      setSearchQuery(val);
    } else {
      setSearchQuery(data);
    }
  };

  const handleQuery = (e) => {
    setQuery(e.target.value);
    searchFilter(query);
  };

  const AddSubscription = () => {
    navigate("/vertical", { replace: true });
  };

  let data = Array.from(users);

  const AdminRoute = (e, business_name) => {
    let business_url = business_name
      .toString()
      .toLowerCase()
      .replace(/\s/g, "");
    window.location.href = `https://${business_url}.${process.env.REACT_APP_ADMIN_URL_DOMAIN}/admin/login`;
  };

  const BorrowerRoute = (e, business_name) => {
    let business_url = business_name
      .toString()
      .toLowerCase()
      .replace(/\s/g, "");
    window.location.href = `https://${business_url}.${process.env.REACT_APP_ADMIN_URL_DOMAIN}/borrower/login`;
  };

  const InstallerRoute = (e, business_name) => {
    let business_url = business_name
      .toString()
      .toLowerCase()
      .replace(/\s/g, "");
    window.location.href = `https://${business_url}.${process.env.REACT_APP_ADMIN_URL_DOMAIN}/installer/login`;
  };

  const LaunchRoute = (e, business_name, subscription_status) => {
    let business_url = business_name
      .toString()
      .toLowerCase()
      .replace(/\s/g, "");
    {
      subscription_status === "Completed"
        ? navigate("/congratulations")
        : (window.location.href = `https://${business_url}.theecentral.com/admin/login`);
    }
  };
  
  const ConfigureRoute = (e, subscription_id) => {
    let token = sessionStorage.getItem("tenantToken");
    navigate(`/control-panel/dashboard/${subscription_id}/${token}`);
  };

  const UnSubscribe = async (e, subscription_id) => {
    try {
      let resp = await Post(`configuration/unsubscribe/${subscription_id}`)
      if(resp) {
        getAllSubscriptions();
        toast.error("Unsubscribed");
      }
    } catch (error) {
      console.log('error::: ', error);
    }

  };

  const gotoSteps = (e, id) => {
    sessionStorage.setItem("subscriptionId", id);
    getStage(id);
  };

  const getStage = (id) => {
    Get(`subscription/getstage/${id}`)
      .then((res) => {
        sessionStorage.setItem("ProductId", res["data"][0]["productId"]);
        sessionStorage.setItem("VerticalId", res["data"][0]["verticalId"]);
        sessionStorage.setItem("tenantId", res["data"][0]["tenantId"]);
        if (res["data"][0]["step"] == 2) {
          let id = sessionStorage.getItem("VerticalId");
          navigate(`/product/${id}`);
        } else if (res["data"][0]["step"] == 3) {
          navigate("/upload-logo", { replace: true });
        } else if (res["data"][0]["step"] == 4) {
          getThemeList();
          navigate("/theme");
        } else if (res["data"][0]["step"] == 5) {
          navigate('/verify-otp')
        }
      })
      .catch((err) => {});
  };

  const getThemeList = () => {
    let productId = sessionStorage.getItem("ProductId");
    let verticalId = sessionStorage.getItem("VerticalId");
    AuthGet(`tenant-theme/themelist/${verticalId}/${productId}`)
      .then((res) => {
        if (res.statusCode !== undefined && res.statusCode === 200) {
          setAllTheme((allTheme) => (allTheme = res.data));
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    dispatch({type: "SET_STEP", payload: null});
    dispatch({type: "SET_CURRENT_STEP", payload: null});
    sessionStorage.removeItem("newSubscription")
  }, [])

  const handleNewSubscription = () => {
    sessionStorage.removeItem("ProductId");
    sessionStorage.removeItem("selectedthemeid");
    sessionStorage.removeItem("VerticalId");
    sessionStorage.removeItem("tenantID");
    sessionStorage.removeItem("themeId");
    sessionStorage.removeItem("subscriptionId");
    sessionStorage.removeItem("selectedVertical");
    sessionStorage.removeItem("businessName");
    sessionStorage.setItem("newSubscription", true)
    navigate("/vertical")
  }

  const openPortal = (bName, Email, subId) => {
    AuthGet(`tenant/checktenant/${subId}`)
      .then((res) => {
        if (res.statusCode == 200 && res.data.result == "verifiedtenant") {
          let email = encodeURIComponent(Email);
          let subscriptionId = encodeURIComponent(subId);
          window.open(
            `https://${bName}.${process.env.REACT_APP_ADMIN_URL_DOMAIN}/admin/${email}/${subscriptionId}`
          );
        } else {
          toast.error(
            "Your Activation in progress, Please contact Help@glenzyfi.com/"
          );
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="fullScreen_bg p0 ">
      <div className="">
        <div className="  box_shadow_none border_radius0">
          <Header />

          <div className="pagecontentblock">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="whiteBox mb_24">
                    <div className="displayFlex justifyContent_spaceBetween Alignitem_center">
                      <h5 className="scatteredShowersText">Subscriptions</h5>

                      <div className="displayFlex justifyContent_spaceBetween Alignitem_center">
                        {/* <div className="mr_20">
                        <button
                          onClick={AddSubscription}
                          className="emptyBtn redLeather_filter"
                        >
                          <img src={addSubscriptions} />
                        </button>
                      </div> */}
                      <div className="mr_20">
                      <button onClick={() => handleNewSubscription()} className="smallPrimarybutton">Add Subscription</button>
                      </div>
                        <div className="mr_20">
                          {/* searchIcon */}
                          <input
                            type="text"
                            className="form_control"
                            placeholder="Search"
                            id="listSearch"
                            ref={searchRef}
                            onInput={(e) => {
                              handleQuery(e);
                            }}
                          />
                        </div>
                        <div>
                        <button
                          onClick={() => setToggleViewMode(!toggleViewMode)}
                          className="emptyBtn"
                        >
                          <img src={toggleViewMode ? gridView : listView} />
                        </button>
                      </div>
                        {/* <div>
                <button
                  onClick={() => setToggleViewMode(!toggleViewMode)}
                  className="emptyBtn"
                >
                  <img src={listView} />
                </button>
              </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="subscriptioncard">
                    {toggleViewMode
                      ? currentSubscriptions.length > 0 &&
                        currentSubscriptions.map((users, index) => {
                          return (
                            <div key={index} className="box_item">
                              <div className="boxHeader">
                                <h3>{users.business_name}</h3>
                              </div>
                              {users.product_name === null && (
                                <>
                                  <img
                                    src={smb}
                                    className="grid_img"
                                    alt="No Subscriptions"
                                  />
                                </>
                              )}
                              {users.product_name === "Health Loans" && (
                                <div>
                                  <img
                                    src={solar}
                                    className="grid_img"
                                    alt="health_business"
                                  />
                                </div>
                              )}
                              {users.product_name === "Business loan" && (
                                <div>
                                  <img
                                    src={smb}
                                    className="grid_img"
                                    alt="smb_business"
                                  />
                                </div>
                              )}
                              {users.product_name === "Home Improvement" && (
                                <div>
                                  <img
                                    src={solar}
                                    className="grid_img"
                                    alt="solar"
                                  />
                                </div>
                              )}

                              {users.product_name === "Auto Loans" && (
                                <div>
                                  <img
                                    src={solar}
                                    className="grid_img"
                                    alt="auto"
                                  />
                                </div>
                              )}

                              <div className="boxBody">
                                <h2 className="mb_4">
                                  BUSINESS NAME
                                  <span>{users.business_name}</span>
                                </h2>

                                {(users.subscription_status === "Verified" ||
                                  users.subscription_status ===
                                    "Completed") && (
                                  <div className="displayFlex Alignitem_center">
                                    <div className="mr_4 urlText"> Url:</div>
                                    <div className="mr_4">
                                      <button
                                        className="urlLink"
                                        onClick={(e) =>
                                          AdminRoute(e, users.business_name)
                                        }
                                      >
                                        Admin Login
                                      </button>
                                    </div>
                                    <div className="mr_4 SquantColor">|</div>
                                    <div className="mr_4">
                                      <button
                                        className="urlLink"
                                        onClick={(e) =>
                                          BorrowerRoute(e, users.business_name)
                                        }
                                      >
                                        Borrower Login
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <div className="border_bottom"></div>
                                <h5>
                                  PRODUCT <span>{users.product_name}</span>
                                </h5>
                                <div className="border_bottom"></div>
                                <h5>
                                  PUBLISH STATUS
                                  <span className="fontWeight_500 Planter_Color">
                                    {users.publish}
                                  </span>
                                </h5>
                                <div className="border_bottom"></div>
                                <h5>
                                  SUBSCRIPTION STATUS
                                  <span className="fontWeight_500 Planter_Color">
                                    {users.subscription_status}
                                  </span>
                                </h5>
                                <div className="border_bottom"></div>

                                <h5 className="mb_16">
                                  SUBSCRIBED ON
                                  <span className="SquantColor fontWeight_500">
                                    {users.created_date}
                                  </span>
                                </h5>

                                {users.step < 6 && (
                                  <>
                                    <button
                                      className="smallPrimaryHoverbutton"
                                      onClick={(e) =>
                                        gotoSteps(e, users.subscription_id)
                                      }
                                    >
                                      Continue
                                    </button>
                                  </>
                                )}

                                {users.product_name === "Residential solar" && (
                                  <>
                                    <div className="mr_4 SquantColor">|</div>
                                    <div>
                                      <button
                                        className="urlLink"
                                        onClick={(e) =>
                                          InstallerRoute(e, users.business_name)
                                        }
                                      >
                                        Installer Login
                                      </button>
                                    </div>
                                  </>
                                )}
                                <div className="border_bottom"></div>
                                {(users.subscription_status === "Verified" ||
                                  users.subscription_status ===
                                    "Completed") && (
                                  <div className="displayFlex Alignitem_center gap_12 flexWrap_wrap">
                                    <div>
                                      {/* <button
                                      className="smallPrimarybutton"
                                      onClick={() => window.open(`http://${users.business_name}.${process.env.REACT_APP_ADMIN_URL_DOMAIN}/admin/login`)}
                                    >
                                      View Back Office
                                    </button> */}
                                    </div>
                                    {/* <div>
                              <button
                                className="smallPrimarybutton"
                                onClick={(e) =>
                                  ConfigureRoute(e, users.subscription_id)
                                }
                              >
                                Configure
                              </button>
                            </div> */}
                                    <div>
                                      <button
                                        className="smallPrimarybutton"
                                        onClick={() => openPortal(users?.business_name, users?.email, users?.subscription_id)}
                                      > 
                                        Back Office
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        className="smallPrimarybutton"
                                        onClick={() => navigate(`/control-panel/dashboard/${users?.subscription_id}/${tenantToken}`)}
                                      >
                                        Configuration
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        className="smallPrimarybutton"
                                        onClick={(e) =>
                                          UnSubscribe(e, users.subscription_id)
                                        }
                                      >
                                        Unsubscribe
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })
                      : currentSubscriptions.length > 0 &&
                        currentSubscriptions.map((users, index) => {
                          return (
                            <div key={index} className="ListviewRow">
                              {users.product_name === null && (
                                <div className="ListviewTableHeadData">
                                  <img
                                    className="listViewImg"
                                    src={nosubs}
                                    alt="No Subscriptions"
                                  />
                                </div>
                              )}
                              {users.product_name === "Health Loans" && (
                                <div className="ListviewTableHeadData">
                                  <img
                                    src={solar}
                                    className="grid_img"
                                    alt="health_business"
                                  />
                                </div>
                              )}
                              {users.product_name === "Business loan" && (
                                <div className="ListviewTableHeadData">
                                  <img
                                    className="listViewImg"
                                    src={smb}
                                    alt="smb_business"
                                  />
                                </div>
                              )}
                              {users.product_name === "Home Improvement" && (
                                <div className="ListviewTableHeadData">
                                  <img
                                    className="listViewImg"
                                    src={solar}
                                    alt="solar"
                                  />
                                </div>
                              )}
                              {users.product_name === "Auto Loans" && (
                                <div className="ListviewTableHeadData">
                                  <img
                                    src={solar}
                                    className="listViewImg"
                                    alt="auto"
                                  />
                                </div>
                              )}
                              <div className="ListviewTableBodyData">
                                <div className="ListviewbodyToprow">
                                  <div className="ListviewbodyTopColum">
                                    <p>BUSINESS NAME:</p>
                                    <h5 className="HorrorSnob_Color">
                                      {users.business_name}
                                    </h5>
                                  </div>
                                  <div className="ListviewbodyTopColum">
                                    <p>PRODUCT:</p>
                                    <h5 className="fontWeight_500 Planter_Color">
                                      {users.product_name}
                                    </h5>
                                  </div>
                                  <div className="ListviewbodyTopColum">
                                    <p>PUBLISH STATUS:</p>
                                    <h5 className="fontWeight_500 Planter_Color">
                                      {users.publish}
                                    </h5>
                                  </div>

                                  <div className="ListviewbodyTopColum">
                                    <p>SUBSCRIPTION STATUS:</p>
                                    <h5 className="fontWeight_500 Planter_Color">
                                      {users.subscription_status}
                                    </h5>
                                  </div>

                                  <div className="ListviewbodyTopColum">
                                    <p className="fontWeight_500">
                                      SUBSCRIBED ON:
                                    </p>
                                    <h5 className="SquantColor fontWeight_500">
                                      {users.created_date}
                                    </h5>
                                  </div>
                                </div>

                                <div className="ListviewbodyBottomrow">
                                  {users.step < 6 && (
                                    <div>
                                      <button
                                        className="smallPrimarybutton"
                                        onClick={(e) =>
                                          gotoSteps(e, users.subscription_id)
                                        }
                                      >
                                        Continue
                                      </button>
                                    </div>
                                  )}
                                  {(users.subscription_status === "Verified" ||
                                    users.subscription_status ===
                                      "Completed") && (
                                    <div className="displayFlex Alignitem_center">
                                      <div className="mr_4 urlText"> URL:</div>
                                      <div className="mr_4">
                                        <button
                                          className="urlLink"
                                          onClick={(e) =>
                                            AdminRoute(e, users.business_name)
                                          }
                                        >
                                          Admin Login
                                        </button>
                                      </div>
                                      <div className="mr_4 SquantColor">|</div>
                                      <div className="mr_4">
                                        <button
                                          className="urlLink"
                                          onClick={(e) =>
                                            BorrowerRoute(
                                              e,
                                              users.business_name
                                            )
                                          }
                                        >
                                          Borrower Login
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {users.product_name ===
                                    "Residential solar" && (
                                    <>
                                      <div className="mr_4 SquantColor">|</div>
                                      <div>
                                        <button
                                          className="urlLink"
                                          onClick={(e) =>
                                            InstallerRoute(
                                              e,
                                              users.business_name
                                            )
                                          }
                                        >
                                          Installer Login
                                        </button>
                                      </div>
                                    </>
                                  )}
                                  <br />
                                  {(users.subscription_status === "Verified" ||
                                    users.subscription_status ===
                                      "Completed") && (
                                    <div className="displayFlex Alignitem_center gap_12 flexWrap_wrap">
                                      <div>
                                        {/* <button
                                        className="smallPrimarybutton"
                                        onClick={() => window.open(`http://${users.business_name}.lendeasy.co/admin/login`)}
                                      >
                                        View Back Office
                                      </button> */}
                                      </div>
                                      {/* <div>
                                <button
                                  className="smallPrimarybutton"
                                  onClick={(e) =>
                                    ConfigureRoute(e, users.subscription_id)
                                  }
                                >
                                  Configure
                                </button>
                              </div> */}
                                      <div>
                                        <button
                                          className="smallPrimarybutton"
                                        onClick={() => openPortal(users?.business_name, users?.email, users?.subscription_id)}
                                        >
                                          Back Office
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          className="smallPrimarybutton"
                                        onClick={() => navigate(`/control-panel/dashboard/${users?.subscription_id}/${tenantToken}`)}
                                        >
                                          Configuration
                                      </button>
                                      </div>
                                      <div>
                                        <button
                                          className="smallPrimarybutton"
                                          onClick={(e) =>
                                            UnSubscribe(
                                              e,
                                              users.subscription_id
                                            )
                                          }
                                        >
                                          Unsubscribe
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    <div className="displayFlex justifyContent_End plr_24 width_100per">
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={users.length}
                        paginate={paginate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantDashboard;
