import React, { useState } from "react";
import modern_treasury from "../../../../assets/slices/modern_treasury.png";
import argyle from "../../../../assets/slices/argyle.png";
import dapi from "../../../../assets/slices/dapi.png";
import idology from "../../../../assets/slices/idology.png";
import metamap from "../../../../assets/slices/metamap.png";
import repay from "../../../../assets/slices/repay.png";
import equifax from "../../../../assets/slices/equifax.png";
import experian from "../../../../assets/slices/experian.png";
import Instnt from "../../../../assets/slices/Instnt.png";
import Persona from "../../../../assets/slices/persona.png";
import Flinks from "../../../../assets/slices/flinks.jpg";
import ShuftiPro from "../../../../assets/slices/shuftipro.svg";
import SaltEdge from "../../../../assets/slices/saltedge.png";
import loanpaymentpro from "../../../../assets/slices/loan_payment_pro.png";
import transunionLogo from "../../../../assets/slices/transunion-logo.png";
import sendgridLogo from "../../../../assets/slices/sendgrid-logo.png";
import plaidLogo from "../../../../assets/slices/plaid-logo.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { AuthGet, AuthPut, AuthPost } from "../../../../_common/httpService";
import { env_variables } from "../../../../_common/environmentVariables";

const Apps = () => {
  const [showPlaid, setshowPlaid] = useState(false);

  const [showMetamap, setshowMetamap] = useState(false);
  const [showArgyle, setshowArgyle] = useState(false);
  const [showDapi, setshowDapi] = useState(false);
  const [showIdology, setshowIdology] = useState(false);
  const [showModernTreasury, setshowModernTreasury] = useState(false);

  const [showSendgrid, setshowSendgrid] = useState(false);
  const [showTransunion, setshowTransunion] = useState(false);
  const [hide, setHide] = useState(false);
  const [plaidParam, setPlaidParam] = useState({});
  const [sendGridParam, setSendGridParam] = useState({});
  const [transunionParam, setTransunionParam] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const subscriptionId = sessionStorage.getItem("subscriptionId");
  const tenantId = sessionStorage.getItem("tenantId");

  const handleCheck = () => {
    setReadOnly(!readOnly);
  };

  const handleClose = () => {
    setshowPlaid(false);
    setshowSendgrid(false);
    setshowTransunion(false);
    setshowModernTreasury(false);
    setshowIdology(false);
    setshowDapi(false);
    setshowArgyle(false);
    setshowMetamap(false);
  };

  const configurationSetting = (id, params) => {
    AuthGet(`configuration/list/${id}`).then((res) => {
      if (res.tenants?.length) {
        AuthPut(`configuration/update`, params).then((res) => {
          if (res.statusCode === 200) {
            handleClose();
          }
        });
      } else {
        AuthPost(`configuration/create`, params).then((res) => {
          if (res.statusCode === 200) {
            handleClose();
          }
        });
      }
    });
  };

  const handleChange = (e, templatename) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    if (templatename === "plaid") {
      setPlaidParam({ ...plaidParam, [name]: value });
    } else if (templatename === "sendgrid") {
      setSendGridParam({ ...sendGridParam, [name]: value });
    } else if (templatename === "transunion") {
      setTransunionParam({ ...transunionParam, [name]: value });
    }
  };

  const submitPlaid = () => {
    if (!readOnly) {
      if (
        plaidParam.plaidClientId !== "" &&
        plaidParam.plaidEve !== "" &&
        plaidParam.plaidSecret !== ""
      ) {
        const params = {
          configuration: [
            {
              subscriptionId: subscriptionId,
              tenantId: tenantId,
              plaidEve: plaidParam.plaidEve,
              plaidClientId: plaidParam.plaidClientId,
              plaidSecret: plaidParam.plaidSecret,
            },
          ],
        };

        configurationSetting(subscriptionId, params);
      }
    } else {
      const params = {
        configuration: [
          {
            subscriptionId: subscriptionId,
            tenantId: tenantId,
            plaidEve: "sandbox",
            plaidClientId: env_variables.PLAID_CLIENT_ID,
            plaidSecret: env_variables.PLAID_SECRET_KEY,
          },
        ],
      };
      configurationSetting(subscriptionId, params);
    }
  };

  const submitSendGrid = () => {
    if (!readOnly) {
      if (
        sendGridParam.sendGridAPIKey !== "" &&
        sendGridParam.sendGridFromMail !== ""
      ) {
        const params = {
          configuration: [
            {
              subscriptionId: subscriptionId,
              tenantId: tenantId,
              sendGridAPIKey: sendGridParam.sendGridAPIKey,
              sendGridFromMail: sendGridParam.sendGridFromMail,
            },
          ],
        };

        configurationSetting(subscriptionId, params);
      }
    } else {
      const params = {
        configuration: [
          {
            subscriptionId: subscriptionId,
            tenantId: tenantId,
            sendGridAPIKey: env_variables.SEND_GRID_KEY,
            sendGridFromMail: env_variables.FROM_MAIL,
          },
        ],
      };
      configurationSetting(subscriptionId, params);
    }
  };

  return (
    <>
      <div className="whiteboxblock">
        <h3 className="  mb_40  sectiontitle  ">Choose the App</h3>

        <div className="row p0  ">
          <div className="col-md-3" onClick={() => setshowPlaid(true)}>
            <div className="bankFrame">
              <img width={120} height={65} src={plaidLogo} alt="plaid" />
            </div>
          </div>
          <div className="col-md-3" onClick={() => setshowSendgrid(true)}>
            <div className="bankFrame">
              <img src={sendgridLogo} alt="sendgrid" />
            </div>
          </div>
          <div className="col-md-3" onClick={() => setshowTransunion(true)}>
            <div className="bankFrame">
              <img src={transunionLogo} alt="transunion" />
            </div>
          </div>
          <div className="col-md-3" onClick={() => setshowModernTreasury(true)}>
            <div className="bankFrame">
              <img  width={130} height={65} src={modern_treasury} alt="modern_treasury" />
            </div>
          </div>

          <div className="col-md-3" onClick={() => setshowArgyle(true)}>
            <div className="bankFrame">
              <img src={argyle} alt="argyle" />
            </div>
          </div>
          <div className="col-md-3" onClick={() => setshowDapi(true)}>
            <div className="bankFrame">
              <img src={dapi} alt="dapi" />
            </div>
          </div>
          <div className="col-md-3" onClick={() => setshowIdology(true)}>
            <div className="bankFrame">
              <img src={idology} alt="idology" />
            </div>
          </div>
          <div className="col-md-3" onClick={() => setshowMetamap(true)}>
            <div className="bankFrame">
              <img src={metamap} alt="metamap" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="bankFrame">
              <img width={120} height={65} src={repay} className="op3" alt="repay" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="bankFrame">
              <img src={equifax} className="op3" alt="equifax" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="bankFrame">
              <img src={experian} className="op3" alt="experian" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="bankFrame">
              <img src={Instnt} className="op3" alt="Instnt" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="bankFrame">
              <img width={130} height={65} src={loanpaymentpro} className="op3" alt="loanpaymentpro" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="bankFrame">
              <img width={130} height={65} src={Persona} className="op3" alt="Persona" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="bankFrame">
              <img width={170} height={75} src={Flinks} className="op3" alt="Flinks" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="bankFrame">
              <img  width={130} height={65} src={ShuftiPro} className="op3" alt="ShuftiPro" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="bankFrame">
              <img  width={130} height={65} src={SaltEdge} className="op3" alt="SaltEdge" />
            </div>
          </div>
        </div>
      </div>

      <div className="apps_section">
        <div className="row"></div>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showPlaid}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Plaid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitPlaid}>
            <div className="checkbox_tick mb_30">
              <input
                id="plaidUserNameCheckBox"
                className="form-check-input"
                type="checkbox"
                name="check"
                checked={readOnly}
                onChange={handleCheck}
              />

              <label htmlFor="plaidUserNameCheckBox">
                <div className="checkboxtxt">Use Glenzy credentials</div>
              </label>
            </div>

            <div className="input_group mb_24">
              <label>Client Id:</label>
              <input
                type="text"
                className="form-control"
                name="plaidClientId"
                placeholder="Client ID"
                value={"✱✱✱✱ ✱✱✱✱ ✱✱✱✱"}
                readOnly={readOnly}
                onChange={(e) => handleChange(e, "plaid")}
              />
            </div>
            <div className="input_group mb_24">
              <label>Secret Key:</label>
              <input
                type="text"
                className="form-control"
                name="plaidSecret"
                placeholder="Secret Key"
                value={"✱✱✱✱✱✱✱✱"}
                readOnly={readOnly}
                onChange={(e) => handleChange(e, "plaid")}
              />
            </div>
            <div className="input_group mb_24">
              <label>Plaid Environment Name:</label>
              <input
                type="text"
                className="form-control"
                name="plaidEve"
                placeholder="Plaid env"
                readOnly={readOnly}
                value={"Theecode"}
                onChange={(e) => handleChange(e, "plaid")}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitPlaid}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSendgrid}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Sendgrid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitSendGrid}>
            <div className="checkbox_tick mb_30">
              <input
                id="plaidUserNameCheckBox"
                className="form-check-input"
                type="checkbox"
                name="check"
                checked={readOnly}
                onChange={handleCheck}
              />

              <label htmlFor="plaidUserNameCheckBox">
                <div className="checkboxtxt">Use Glenzy credentials</div>
              </label>
            </div>
            <div className="input_group mb_30">
              <label>Send Grid API Key:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridAPIKey"
                value={"✱✱✱✱-✱✱✱✱-✱✱✱✱-✱✱✱✱✱✱✱✱"}
                onChange={(e) => handleChange(e, "sendgrid")}
                readOnly={readOnly}
              />
            </div>
            <div className="input_group mb_30">
              <label>From Email:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridFromMail"
                value={"✱✱✱✱✱✱@theecode.xyz"}
                onChange={(e) => handleChange(e, "sendgrid")}
                readOnly={readOnly}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitSendGrid}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showTransunion}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Transunion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="checkbox_tick mb_30">
              <input
                id="transunion"
                type="checkbox"
                checked={readOnly}
                onChange={handleCheck}
              />
              <label htmlFor="transunion">
                <div className="checkboxtxt">Use Glenzy credentials</div>
              </label>
            </div>
            <form>
              <div className="row">
                <div className="col-md-4 input_group mb_24">
                  <label>Product Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="productCode"
                    placeholder="Product Code"
                    value={"✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Processing Environment</label>
                  <input
                    type="text"
                    className="form-control"
                    name="processingEnvironment"
                    placeholder="Processing Environment"
                    value={"✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Prefix Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="prefixCode"
                    placeholder="Prefix Code"
                    value={"✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Industry Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="industrycode"
                    placeholder="Industry Code"
                    value={"✱✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Member Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="memberCode"
                    placeholder="Member Code"
                    value={"✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Member Code Hard Pull</label>
                  <input
                    type="text"
                    className="form-control"
                    name="memberCodeHardPull"
                    placeholder="Member Code Hard Pull"
                    value={"✱✱✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Password</label>
                  <input
                    type="text"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    value={"✱✱✱✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Enable Request Xml Logging </label>
                  <input
                    type="text"
                    className="form-control"
                    name="enableReq"
                    placeholder="Enable Request Xml Logging"
                    value={"✱✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Enable Response Xml Logging </label>
                  <input
                    type="text"
                    className="form-control"
                    name="responseReq"
                    placeholder="Enable Response Xml Logging"
                    value={"✱✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Certificate – Certificate Path </label>
                  <input
                    type="text"
                    className="form-control"
                    name="certPath"
                    placeholder="Certificate Path"
                    value={"✱✱✱✱✱✱✱✱✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Certificate – Key Path </label>
                  <input
                    type="text"
                    className="form-control"
                    name="certKey"
                    placeholder="Certificate Key Path"
                    value={"✱✱✱✱✱✱✱✱✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-4 input_group mb_24">
                  <label>Certificate – Password </label>
                  <input
                    type="text"
                    className="form-control"
                    name="certPassword"
                    placeholder="Certificate Password"
                    value={"✱✱✱✱✱✱✱✱"}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modern treasury */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModernTreasury}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modern Treasury
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitSendGrid}>
            <div className="checkbox_tick mb_30">
              <input
                id="plaidUserNameCheckBox"
                className="form-check-input"
                type="checkbox"
                name="check"
                checked={readOnly}
                onChange={handleCheck}
              />

              <label htmlFor="plaidUserNameCheckBox">
                <div className="checkboxtxt">Use Glenzy credentials</div>
              </label>
            </div>
            <div className="input_group mb_30">
              <label>Organization ID:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridAPIKey"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱ ✱✱✱✱ ✱✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
            <div className="input_group mb_30">
              <label>Secret Key:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridFromMail"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
            <div className="input_group mb_30">
              <label>Organization Account ID:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridFromMail"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitSendGrid}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Metamap */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showMetamap}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Metamap</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitSendGrid}>
            <div className="checkbox_tick mb_30">
              <input
                id="plaidUserNameCheckBox"
                className="form-check-input"
                type="checkbox"
                name="check"
                checked={readOnly}
                onChange={handleCheck}
              />

              <label htmlFor="plaidUserNameCheckBox">
                <div className="checkboxtxt">Use Glenzy credentials</div>
              </label>
            </div>
            <div className="input_group mb_30">
              <label>Client ID:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridAPIKey"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱✱ ✱✱✱ ✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
            <div className="input_group mb_30">
              <label>Client Secret:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridFromMail"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
            <div className="input_group mb_30">
              <label>Metamap (Flow) ID:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridFromMail"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitSendGrid}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Argyle */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showArgyle}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Sendgrid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitSendGrid}>
            <div className="checkbox_tick mb_30">
              <input
                id="plaidUserNameCheckBox"
                className="form-check-input"
                type="checkbox"
                name="check"
                checked={readOnly}
                onChange={handleCheck}
              />

              <label htmlFor="plaidUserNameCheckBox">
                <div className="checkboxtxt">Use Glenzy credentials</div>
              </label>
            </div>
            <div className="input_group mb_30">
              <label>Send Grid API Key:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridAPIKey"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱-✱✱✱✱-✱✱✱✱-✱✱✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
            <div className="input_group mb_30">
              <label>From Email:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridFromMail"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱✱✱@theecode.xyz"}
                readOnly={readOnly}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitSendGrid}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Dapi */}

      <Modal
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDapi}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Dapi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitSendGrid}>
            <div className="checkbox_tick mb_30">
              <input
                id="plaidUserNameCheckBox"
                className="form-check-input"
                type="checkbox"
                name="check"
                checked={readOnly}
                onChange={handleCheck}
              />

              <label htmlFor="plaidUserNameCheckBox">
                <div className="checkboxtxt">Use Glenzy credentials</div>
              </label>
            </div>
            <div className="input_group mb_30">
              <label> API Key:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridAPIKey"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱-✱✱✱✱-✱✱✱✱-✱✱✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
            <div className="input_group mb_30">
              <label>From Email:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridFromMail"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱✱✱@theecode.xyz"}
                readOnly={readOnly}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitSendGrid}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Idology */}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showIdology}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Sendgrid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitSendGrid}>
            <div className="checkbox_tick mb_30">
              <input
                id="plaidUserNameCheckBox"
                className="form-check-input"
                type="checkbox"
                name="check"
                checked={readOnly}
                onChange={handleCheck}
              />

              <label htmlFor="plaidUserNameCheckBox">
                <div className="checkboxtxt">Use Glenzy credentials</div>
              </label>
            </div>
            <div className="input_group mb_30">
              <label>Send Grid API Key:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridAPIKey"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱-✱✱✱✱-✱✱✱✱-✱✱✱✱✱✱✱✱"}
                readOnly={readOnly}
              />
            </div>
            <div className="input_group mb_30">
              <label>From Email:</label>
              <input
                type="text"
                className="form-control"
                name="sendGridFromMail"
                onChange={(e) => handleChange(e, "sendgrid")}
                value={"✱✱✱✱✱✱@theecode.xyz"}
                readOnly={readOnly}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitSendGrid}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Apps;
